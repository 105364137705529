import { zodResolver } from '@hookform/resolvers/zod';
import usePrice from 'hooks/usePrice';
import useRouter from 'hooks/useRouter';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { IconButton } from 'ui/Button/Button';
import { Form, FormControl, FormField, FormItem, FormMessage } from 'ui/Form/Form';
import { Input } from 'ui/Input/Input';
import { Slider } from 'ui/Slider/Slider';
import { Text } from 'ui/Text/Text';
import { getLocalizedPathname } from 'utils/localization';
import { parseSolrPriceRange, parseSolrQuery, updateSolrQuery } from 'utils/solrUtils';
import { getRouterParam } from 'utils/urlUtil';
import { requiredValidation } from 'utils/zod';
import { z } from 'zod';

type FacetPriceSelectProps = {
  facetDefinitionCode: string;
  max: number;
  min: number;
  onUpdateUrl?: (facetCode: string, facetFilterValueCode: string) => void;
};
type MinMax = { max: number; min: number };

const getMinMaxFromQuery = (query: string, facetDefinitionCode: string) => {
  const parsedQuery = parseSolrQuery(query);
  const currentPriceRange = parsedQuery[facetDefinitionCode];
  const minMax = currentPriceRange ? parseSolrPriceRange(currentPriceRange) : { max: 0, min: 0 };
  return minMax;
};

const formSchema = z.object({
  max: requiredValidation,
  min: requiredValidation,
});

type FormValues = z.infer<typeof formSchema>;

const FacetPriceSelect = ({ facetDefinitionCode, max, min, onUpdateUrl }: FacetPriceSelectProps) => {
  const { formatPrice, parsePrice } = usePrice({ minimumFractionDigits: 0, style: 'decimal' });
  const router = useRouter();
  const { formatMessage } = useIntl();
  const { isReady, locale, pathname, query } = router;
  const [currentValues, setCurrentValues] = useState<MinMax>({ max, min });
  const queryParam = getRouterParam(query.q);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    values: {
      max: currentValues.max + '',
      min: currentValues.min + '',
    },
  });

  useEffect(() => {
    if (!isReady) return;
    const { max: maxFromQuery, min: minFromQuery } = getMinMaxFromQuery(queryParam, facetDefinitionCode);
    const newMax = maxFromQuery || max;
    const newMin = minFromQuery || min;
    setCurrentValues({ max: newMax, min: newMin });
  }, [queryParam, isReady, max, min]);

  const commitPriceRange = (values: number[]) => {
    // If onUpdateUrl is provided, we are using regular query params
    if (onUpdateUrl) {
      return onUpdateUrl(facetDefinitionCode, `${values[0]}-${values[1]}`);
    }
    // If onUpdateUrl is not provided, we are using a Solr query
    const updatedQuery = updateSolrQuery(queryParam, facetDefinitionCode, `[${values[0]} TO ${values[1]}]`);
    const localizedPathname = getLocalizedPathname({
      locale,
      pathname,
    });
    router.push({
      pathname: localizedPathname,
      query: {
        ...query,
        q: updatedQuery,
      },
    });
  };

  const onValueChange = (values: number[]) => {
    setCurrentValues({ max: values[1], min: values[0] });
  };

  const handleSubmit = (values: FormValues) =>
    commitPriceRange([Number(values.min ?? currentValues.min), Number(values.max ?? currentValues.max)]);

  const handleInputValueChange = (value: string, min: number, max: number) => {
    const numericValueOnly = parsePrice(value);

    if (numericValueOnly > max) return Math.ceil(max);

    if (numericValueOnly < min) return Math.floor(min);

    return numericValueOnly;
  };

  return (
    <div className="flex flex-col gap-6 md:gap-4">
      <Slider
        className="mt-2 w-full"
        defaultValue={[Math.floor(min), Math.ceil(max)]}
        value={[currentValues.min, currentValues.max]}
        max={max}
        min={min}
        step={1}
        onValueCommit={commitPriceRange}
        onValueChange={onValueChange}
      />

      <Form {...form}>
        <form className="flex items-center gap-4" onSubmit={form.handleSubmit(handleSubmit)}>
          <Text>€</Text>
          <FormField
            control={form.control}
            name="min"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormControl>
                  <Input
                    {...field}
                    className="px-2 text-center"
                    value={formatPrice(Number(field.value))}
                    onBlur={(e) => field.onChange(String(handleInputValueChange(e.target.value, min, max)))}
                    onChange={(e) => field.onChange(String(parsePrice(e.target.value)))}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <Text>{formatMessage({ id: 'search_facets_price_range_to' })}</Text>
          <FormField
            control={form.control}
            name="max"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormControl>
                  <Input
                    {...field}
                    className="px-2 text-center"
                    value={formatPrice(Number(field.value))}
                    onBlur={(e) => field.onChange(String(handleInputValueChange(e.target.value, min, max)))}
                    onChange={(e) => field.onChange(String(parsePrice(e.target.value)))}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <IconButton
            type="submit"
            className="size-10 shrink-0"
            icon={{
              name: 'angle-right',
              styling: 'fal',
            }}
          />
        </form>
      </Form>
    </div>
  );
};
export default FacetPriceSelect;
