import useRouter from 'hooks/useRouter';
import { localizedPathnames } from 'i18n/pathnames';
import { Facet } from 'types/Facet';
import { Icon } from 'ui/Icon/Icon';
import { cn } from 'utils/cn';
import FacetCheckBox from '../FacetCheckBox/FacetCheckBox';

type FacetReviewSelectProps = {
  facetOptions: Facet[];
};

const FacetReviewSelect = ({ facetOptions }: FacetReviewSelectProps) => {
  const router = useRouter();
  const { locale, pathname, query } = router;

  const updateUrl = (newQuery?: string) => {
    if (newQuery === undefined) {
      return;
    }

    const localizedPathname = localizedPathnames[pathname]?.[locale] || pathname;

    router.push(
      {
        pathname: localizedPathname,
        query: {
          ...query,
          currentPage: 0,
          q: newQuery,
        },
      },
      undefined,
      { shallow: true },
    );

    window.scrollTo({ behavior: 'smooth', top: 0 });
  };

  return (
    <div className="flex flex-col gap-3">
      {facetOptions.map((facetOption) => {
        const rating = parseInt(facetOption.code);
        const starMap = Array.from({ length: 5 }, (_, index) => index < rating);

        return (
          <FacetCheckBox
            key={facetOption.code}
            id={facetOption.code}
            className="flex items-center gap-2"
            checked={facetOption.selected}
            onCheckedChange={() => updateUrl(facetOption.query?.query?.value)}
          >
            <div className="flex items-center gap-1">
              {starMap.map((star, index) => (
                <Icon
                  key={`facet-review-${facetOption.code}-star-${index}`}
                  icon={{ name: 'star', styling: 'fas' }}
                  className={cn(star ? 'text-yellow-400' : 'text-accent-40', 'h-4 w-4')}
                />
              ))}
              ({facetOption.count})
            </div>
          </FacetCheckBox>
        );
      })}
    </div>
  );
};
export default FacetReviewSelect;
