import useRouter from 'hooks/useRouter';
import { Facet } from 'types/Facet';
import { Button } from 'ui/Button/Button';
import { Text } from 'ui/Text/Text';
import { cn } from 'utils/cn';

type FacetPillProps = {
  className?: string;
  facetOption: Facet;
};

const FacetColorButton = ({ className, facetOption }: FacetPillProps) => {
  const router = useRouter();

  const { query } = router;

  const updateUrl = () => {
    router.push(
      {
        query: {
          ...query,
          currentPage: 0,
          q: facetOption.query?.query?.value,
        },
      },
      undefined,
      { shallow: true },
    );
  };
  return (
    <Button
      id={facetOption.code}
      className={cn(
        'text-primary flex flex-row items-center gap-2 rounded-full border border-accent-60 p-1',
        facetOption.selected ? 'border-primary-30 bg-primary-30/20' : '',
        className,
      )}
      variant="ghost"
      onClick={updateUrl}
    >
      <div style={{ backgroundColor: facetOption.color }} className="size-6 rounded-full" />
      <Text type="small" className="pr-2">
        {facetOption.name}
      </Text>
    </Button>
  );
};
export default FacetColorButton;
