import useRouter from 'hooks/useRouter';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { normalizePath } from '../../utils/textUtil';
import PageHead from '../PageHead/PageHead';

interface SEOProps {
  basePathOverwrite?: string;
  children?: ReactNode;
  description?: string;
  robots?: string;
  title?: string;
  titleKey?: string;
  useAsPath?: boolean;
}

const SEO = ({
  basePathOverwrite,
  children,
  description,
  robots = 'follow, index',
  title,
  titleKey,
  useAsPath,
}: SEOProps) => {
  const { formatMessage } = useIntl();
  const router = useRouter();

  const buildTitle = () => {
    if (title) {
      return title;
    }

    // e.g. {Path} - Text from translation key
    if (useAsPath && router.asPath) {
      const asPath = normalizePath(router.asPath);

      return formatMessage(
        {
          id: 'seo_content_page_title',
        },
        {
          value: asPath,
        }
      );
    }

    // e.g. {TitleKey} - Text from translation key
    if (titleKey) {
      return formatMessage(
        {
          id: 'seo_content_page_title',
        },
        {
          value: titleKey,
        }
      );
    }

    // return if no prop is given
    return formatMessage({
      id: 'seo_home_page_title',
    });
  };

  const buildDescription = () => {
    if (description) {
      return description;
    }

    return formatMessage({
      id: 'seo_home_page_meta_description',
    });
  };

  return (
    <>
      <PageHead
        basePathOverwrite={basePathOverwrite}
        description={buildDescription()}
        robots={robots}
        title={buildTitle()}
      />
      {children}
    </>
  );
};

export default SEO;
