'use client';

import * as SliderPrimitive from '@radix-ui/react-slider';

import * as React from 'react';
import { cn } from 'utils/cn';

const thumbClass =
  'border-primary block h-5 w-5 rounded-full border-2 bg-background ring-offset-background transition-colors focus-visible:outline-none focus-border disabled:pointer-events-none disabled:opacity-50';

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => {
  const isRangeSlider = (props.defaultValue?.length ?? 0) > 1;
  return (
    <SliderPrimitive.Root
      ref={ref}
      className={cn('relative flex w-full touch-none select-none items-center', className)}
      minStepsBetweenThumbs={props.minStepsBetweenThumbs ?? isRangeSlider ? 1 : undefined}
      {...props}
    >
      <SliderPrimitive.Track className="relative h-1 w-full grow overflow-hidden rounded-full bg-accent-60">
        <SliderPrimitive.Range className="absolute h-full bg-primary-40" />
      </SliderPrimitive.Track>
      <SliderPrimitive.Thumb className={thumbClass} />
      {isRangeSlider && <SliderPrimitive.Thumb className={thumbClass} />}
    </SliderPrimitive.Root>
  );
});
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
