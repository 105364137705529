import { KeyboardEvent, MouseEvent, useState } from 'react';
import { PopupEntry as PopupEntryType } from 'types/Popup';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from 'ui/Dialog/Dialog';
import { InfoIcon } from 'ui/Info/Info';
import PopupEntry from './PopupEntry';

interface PopupWithEntriesProps {
  className?: string;
  entries: PopupEntryType[];
  title?: string;
}

const PopupWithEntries = ({ className, entries, title }: PopupWithEntriesProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  if (!entries.length) return null;

  const handleOpenDialog = (e: MouseEvent<SVGSVGElement> | KeyboardEvent<SVGSVGElement>) => {
    e.stopPropagation();
    setDialogOpen(true);
  };

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <InfoIcon
        className={className}
        onClick={(e) => {
          handleOpenDialog(e);
        }}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === ' ' || e.key === 'Spacebar') {
            handleOpenDialog(e);
          }

          if (e.key === 'Enter') {
            handleOpenDialog(e);
            e.preventDefault();
          }
        }}
      />
      <DialogContent className="lg:w-full lg:max-w-[1024px]" onClick={(e) => e.stopPropagation()}>
        <DialogHeader>{title && <DialogTitle>{title}</DialogTitle>}</DialogHeader>

        <div className="flex flex-col gap-8">
          {entries.map((entry) => (
            <PopupEntry key={entry.code} entry={entry} />
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default PopupWithEntries;
