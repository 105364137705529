import { FacetDefinition } from 'types/Facet';
import { useRouterParam } from './useRouterParam';

export const useFacetFilterInfo = (facetDefinitions?: FacetDefinition[]) => {
  const q = useRouterParam('q');
  const hasAppliedFacets = facetDefinitions?.some((facet) => facet.values.some((value) => value.selected)) || !!q;

  return {
    hasAppliedFacets,
  };
};
