import * as React from 'react';
import { tv } from 'tailwind-variants';

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  onClear?: () => void;
  state?: 'error' | 'success';
};

export const inputFieldVariants = tv({
  base: 'placeholder:text-accent-60 w-full px-5 py-3 focus:ring-0 focus-visible:outline-none border border-accent-40 rounded focus:border-accent-40',
  variants: {
    state: {
      error: 'border-secondary-40 bg-secondary-40/[0.05]',
      success: 'border-success bg-success/[0.05]',
    },
  },
});

const Input = React.forwardRef<HTMLInputElement, Props>(
  ({ className, name, state, type = 'text', value, ...props }, ref) => (
    <input
      className={inputFieldVariants({ className, state })}
      name={name}
      ref={ref}
      type={type}
      value={value}
      {...props}
    />
  ),
);

Input.displayName = 'Input';

export { Input };
