import { useIntl } from 'react-intl';
import { Button } from 'ui/Button/Button';

type FacetShowMoreProps = {
  amountOfItems: number;
  minAmountOfItemsToShow: number;
  setShowAll: (value: boolean) => void;
  showAll: boolean;
};

const FacetShowMore = ({ amountOfItems, minAmountOfItemsToShow, setShowAll, showAll }: FacetShowMoreProps) => {
  const { formatMessage } = useIntl();
  const showButton = amountOfItems > minAmountOfItemsToShow;

  if (!showButton) return null;

  return (
    <div className="flex justify-start">
      <Button
        variant="ghost"
        className="p-0 text-primary-30 underline lg:no-underline"
        onClick={() => setShowAll(!showAll)}
      >
        {showAll
          ? formatMessage({ id: 'search_facets_filters_show_less' })
          : formatMessage({ id: 'search_facets_filters_show_more' })}
      </Button>
    </div>
  );
};
export default FacetShowMore;
