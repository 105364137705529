import { useIntl } from 'react-intl';
import { Button } from 'ui/Button/Button';
import { Icon } from 'ui/Icon/Icon';
import { Sheet, SheetClose, SheetContent, SheetFooter, SheetHeader, SheetTitle } from 'ui/Sheet/Sheet';
import { cn } from 'utils/cn';

type FacetSheetProps = {
  amountOfResults: number;
  children?: React.ReactNode;
  className?: string;
  hasAppliedFacetFilters: boolean;
  onOpenChange?: (open: boolean) => void;
  open?: boolean;
  resetFacetFilters: () => void;
};

const FacetSheet = ({
  amountOfResults,
  children,
  className,
  hasAppliedFacetFilters,
  onOpenChange,
  open,
  resetFacetFilters,
}: FacetSheetProps) => {
  const { formatMessage } = useIntl();
  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent
        className={cn('z-50 flex h-fit max-h-[65vh] w-full flex-col gap-0 rounded-tr-sheet p-0', className)}
        side="bottom"
      >
        <SheetHeader className="flex flex-row items-center justify-between p-4">
          <SheetTitle>
            {formatMessage({
              id: 'search_facets_filters_title',
            })}
          </SheetTitle>
          <SheetClose>
            <Icon icon={{ name: 'times', styling: 'far' }} />
          </SheetClose>
        </SheetHeader>
        <div className="overflow-auto px-4">{children}</div>
        <SheetFooter className="flex justify-center gap-2 px-6 shadow-top">
          {hasAppliedFacetFilters && (
            <Button className="w-full" variant="ghost" onClick={resetFacetFilters}>
              {formatMessage({
                id: 'search_facet_breadcrumbs_reset_filters',
              })}
            </Button>
          )}
          <Button className="w-full" variant="secondary" onClick={() => onOpenChange?.(false)}>
            {formatMessage(
              {
                id: 'search_facets_filters_confirm',
              },
              { count: amountOfResults },
            )}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};
export default FacetSheet;
