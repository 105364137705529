import { FacetDefinition } from 'types/Facet';
import FacetColorSelect from '../FacetColorSelect/FacetColorSelect';
import FacetMultiSelect from '../FacetMultiSelect/FacetMultiSelect';
import FacetPriceSelect from '../FacetPriceSelect/FacetPriceSelect';
import FacetReviewSelect from '../FacetReviewSelect/FacetReviewSelect';

type FacetFilterItemProps = {
  facetDefinition: FacetDefinition;
  onUpdateUrl?: (facetCode: string, facetFilterValueCode: string) => void;
};

const FacetFilterItem = ({ facetDefinition, onUpdateUrl }: FacetFilterItemProps) => {
  const { code: facetDefinitionCode, facetDescriptor, values: facetOptions } = facetDefinition;

  switch (facetDescriptor) {
    case 'COLOR':
      return <FacetColorSelect facetOptions={facetOptions} />;
    case 'REVIEW':
      return <FacetReviewSelect facetOptions={facetOptions} />;
    case 'SLIDER': {
      return (
        <FacetPriceSelect
          facetDefinitionCode={facetDefinitionCode}
          min={facetDefinition.min}
          max={facetDefinition.max}
          onUpdateUrl={onUpdateUrl}
        />
      );
    }
    default:
      return (
        <FacetMultiSelect
          facetOptions={facetOptions}
          onUpdateUrl={onUpdateUrl}
          facetDefinitionCode={facetDefinitionCode}
        />
      );
  }
};

export default FacetFilterItem;
