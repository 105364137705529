import { PopupEntry as PopupEntryType } from 'types/Popup';
import { Image } from 'ui/Image/Image';
import { Text } from 'ui/Text/Text';
import { cn } from 'utils/cn';
import { Player, PlayerWrapper } from './PopupEntry.styled';

interface PopupEntryProps {
  entry: PopupEntryType;
}

const PopupEntry = ({ entry }: PopupEntryProps) => {
  const { description, externalMedia, media, mediaPosition, title } = entry;
  const hasMedia = media?.url || externalMedia;

  return (
    <div className={cn('flex flex-col gap-8 lg:grid lg:grid-cols-3')}>
      <div className={hasMedia ? 'lg:col-span-2' : 'lg:col-span-full'}>
        {title && (
          <Text type="h4" className="mb-2 text-justify">
            {title}
          </Text>
        )}
        {description && <Text html={description} className="text-justify [&>*:last-child]:mb-0" />}
      </div>
      {hasMedia && (
        <div
          className={cn(
            'relative z-base h-full w-full flex-shrink-0 overflow-hidden rounded',
            mediaPosition === 'LEFT' ? 'lg:row-start-1' : '',
          )}
        >
          {externalMedia && (
            // TODO Refactor to tailwind
            <PlayerWrapper className="player-wrapper h-[216px]">
              <Player className="player" url={externalMedia} controls height="100%" width="100%" />
            </PlayerWrapper>
          )}

          {media?.url && !externalMedia && (
            <div className="aspect-16/9">
              <Image
                className="object-contain"
                src={media?.url}
                alt={title ?? ''}
                useNext
                quality={100}
                fill
                nextSizes={{ default: 500, lg: 1000 }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default PopupEntry;
