import { ButtonProps } from 'components/Button/Button';
import { Spacer } from 'constants/styling';
import { memo } from 'react';
import { useIntl } from 'react-intl';
import { ICON_NAMES, Icon as IconType } from '../../../constants/icon';
import Flex from '../../Flex/Flex';
import Icon from '../../Icon/Icon';
import Link from '../../Link/Link';
import Typography from '../../Typography/Typography';
import {
  StyledButton,
  StyledChecklist,
  StyledChecklistItem,
  StyledContent,
  StyledWrapper,
} from './SearchNoResults.styled';

interface SearchNoResultButton extends ButtonProps {
  href?: string;
  label?: string;
  onClick: () => void;
  outlined?: boolean;
}

interface SearchNoResults {
  buttons?: SearchNoResultButton[];
  children?: React.ReactNode;
  className?: string;
  hasChecks?: boolean;
  icon?: IconType;
  iconSize?: Spacer;
  isSearching?: boolean;
  title?: string;
}

const SearchNoResults = ({
  buttons,
  children,
  className,
  hasChecks,
  icon = ICON_NAMES.TELESCOPE,
  iconSize = 400,
  title,
}: SearchNoResults) => {
  const { formatMessage } = useIntl();

  const noResultCheckIds = ['search_no_results_check_1', 'search_no_results_check_2', 'search_no_results_check_3'];

  return (
    <StyledWrapper className={className}>
      <StyledContent flexDirection="column">
        <Icon type="custom" color="white" name={icon} size={iconSize} />

        <Typography color="white" marginBottom={0} type="h2">
          {title}
        </Typography>
        {hasChecks && (
          <StyledChecklist className="no-search-results-checklist" hasIcons>
            {noResultCheckIds.map((id) => (
              <StyledChecklistItem key={id} color="white" icon={ICON_NAMES.CHECK}>
                <Typography color="white">{formatMessage({ id })}</Typography>
              </StyledChecklistItem>
            ))}
          </StyledChecklist>
        )}
        {children}
        {!!buttons?.length && (
          <Flex gap={75}>
            {buttons.map((button, index) => {
              const buttonComponent = (
                <StyledButton key={button.label} {...button} outlined={index % 2 === 0}>
                  {button.label}
                </StyledButton>
              );

              if (button?.href) {
                return (
                  <Link key={button.label} url={button.href}>
                    {buttonComponent}
                  </Link>
                );
              }

              return buttonComponent;
            })}
          </Flex>
        )}
      </StyledContent>
    </StyledWrapper>
  );
};

export default memo(
  SearchNoResults,
  // Not re-rendering when it's searching allows to avoid inconsistent UIs
  (_, nextProps) => nextProps.isSearching === true,
);
