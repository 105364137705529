import useRouter from './useRouter';

const useToggleURLSearchParams = () => {
  const router = useRouter();

  const toggleQueryParam = (name: string, value: string) => {
    const { query } = router;

    let newQuery = { ...query };

    if (query[name] === value) {
      // Parameter exists in the URL, so remove it
      delete newQuery[name];
    } else {
      // Parameter doesn't exist in the URL, so add it
      newQuery = {
        ...query,
        [name]: value,
      };
    }

    router.push(
      {
        pathname: router.pathname,
        query: newQuery,
      },
      undefined,
      { shallow: true },
    );
  };

  return toggleQueryParam;
};

export default useToggleURLSearchParams;
