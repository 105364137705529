import { useRouter } from 'hooks/useRouter';
import { localizedPathnames } from 'i18n/pathnames';
import { useState } from 'react';
import { Facet } from 'types/Facet';
import FacetCheckBox from '../FacetCheckBox/FacetCheckBox';
import FacetShowMore from '../FacetShowMore/FacetShowMore';

const MIN_AMOUNT_OF_ITEMS_TO_SHOW = 5;

type FacetMultiSelectProps = {
  facetDefinitionCode?: string;
  facetOptions: Facet[];
  onUpdateUrl?: (facetCode: string, facetFilterValueCode: string) => void;
};

const FacetMultiSelect = ({ facetDefinitionCode, facetOptions, onUpdateUrl }: FacetMultiSelectProps) => {
  const router = useRouter();
  const { locale, pathname, query } = router;

  const [showAll, setShowAll] = useState(false);
  const visibleFacets = showAll ? facetOptions : facetOptions.slice(0, MIN_AMOUNT_OF_ITEMS_TO_SHOW);

  const updateUrl = (facetOption: Facet) => {
    const newQuery = facetOption.query?.query.value;

    if (onUpdateUrl && facetDefinitionCode) {
      return onUpdateUrl(facetDefinitionCode, facetOption.code);
    }

    if (newQuery === undefined) {
      return;
    }

    const localizedPathname = localizedPathnames[pathname]?.[locale] || pathname;

    router.push(
      {
        pathname: localizedPathname,
        query: {
          ...query,
          currentPage: 0,
          q: newQuery,
        },
      },
      undefined,
      { shallow: true },
    );

    window.scrollTo({ behavior: 'smooth', top: 0 });
  };

  return (
    <div className="flex flex-col gap-3">
      {visibleFacets.map((facetOption) => (
        <FacetCheckBox
          key={facetOption.code}
          id={facetOption.code}
          className="flex items-center gap-2"
          checked={facetOption.selected}
          onCheckedChange={() => updateUrl(facetOption)}
        >
          {facetOption.name} ({facetOption.count})
        </FacetCheckBox>
      ))}
      <FacetShowMore
        showAll={showAll}
        setShowAll={setShowAll}
        amountOfItems={facetOptions?.length}
        minAmountOfItemsToShow={MIN_AMOUNT_OF_ITEMS_TO_SHOW}
      />
    </div>
  );
};
export default FacetMultiSelect;
