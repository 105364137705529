import PopupWithEntries from 'components/Layout/PopupWithEntries/PopupWithEntries';
import { useFacetFilterInfo } from 'hooks/useFacetFilterInfo';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { FacetDefinition } from 'types/Facet';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from 'ui/Accordion/Accordion';
import { cn } from 'utils/cn';
import {
  FacetFilterHeader,
  FacetFilterHeaderSubtitle,
  FacetFilterHeaderTitle,
} from '../FacetFilterHeader/FacetFilterHeader';
import FacetFilterItem from '../FacetFilterItem/FacetFilterItem';
import FacetResetButton from '../FacetResetButton/FacetResetButton';

interface FacetFiltersProps {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  defaultOpenAll?: boolean;
  facetDefinitions: FacetDefinition[];
  onResetFilters?: () => void;
  onUpdateUrl?: (facetCode: string, facetFilterValueCode: string) => void;
}

const FacetFilters = ({
  children,
  className,
  defaultOpenAll,
  facetDefinitions,
  onResetFilters,
  onUpdateUrl,
}: FacetFiltersProps) => {
  const { formatMessage } = useIntl();
  const initialOpenFacetDefinitions = useMemo(
    () => (defaultOpenAll ? facetDefinitions.map((facetDefinition) => facetDefinition.code) : []),
    [defaultOpenAll, facetDefinitions],
  );
  const [openFacetDefinitions, setOpenFacetDefinitions] = useState<string[]>(initialOpenFacetDefinitions);
  const { hasAppliedFacets } = useFacetFilterInfo(facetDefinitions);

  useEffect(() => {
    setOpenFacetDefinitions(initialOpenFacetDefinitions);
  }, [initialOpenFacetDefinitions]);

  return (
    <Accordion
      className={cn('md:px-4', className)}
      type="multiple"
      value={openFacetDefinitions}
      onValueChange={setOpenFacetDefinitions}
    >
      {hasAppliedFacets && <FacetResetButton className="hidden p-0 pt-4 lg:inline-flex" onClick={onResetFilters} />}
      {children}
      {facetDefinitions.map((facetDefinition, index) => {
        const amountOfSelectedFilters = facetDefinition?.values?.filter((value) => value.selected)?.length;

        return (
          <AccordionItem
            key={facetDefinition.code}
            value={facetDefinition.code}
            className={cn(index === facetDefinitions.length - 1 ? 'border-b-0' : '')}
          >
            <AccordionTrigger className="lg:py-6">
              <FacetFilterHeader>
                <FacetFilterHeaderTitle
                  title={
                    <>
                      {facetDefinition.name}
                      {!!facetDefinition.facetPopupEntries?.length && (
                        <PopupWithEntries title={facetDefinition.name} entries={facetDefinition.facetPopupEntries} />
                      )}
                    </>
                  }
                />
                <FacetFilterHeaderSubtitle
                  subtitle={
                    amountOfSelectedFilters
                      ? formatMessage({ id: 'category-amount-of-filters' }, { value: amountOfSelectedFilters })
                      : undefined
                  }
                />
              </FacetFilterHeader>
            </AccordionTrigger>
            <AccordionContent>
              <FacetFilterItem facetDefinition={facetDefinition} onUpdateUrl={onUpdateUrl} />
            </AccordionContent>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

export default FacetFilters;
