import React from 'react';
import { Text, TextProps } from 'ui/Text/Text';
import { cn } from 'utils/cn';

interface FacetFilterHeaderTitleProps extends Omit<TextProps, 'title'> {
  className?: string;
  title: React.ReactNode | React.ReactNode[];
}

const FacetFilterHeaderTitle = ({ className, title }: FacetFilterHeaderTitleProps) => (
  <Text
    type="h4"
    tag="span"
    className={cn('flex items-center gap-2 text-left font-h4 font-normal lg:font-bold', className)}
  >
    {title}
  </Text>
);

interface FacetFilterHeaderSubtitleProps extends TextProps {
  className?: string;
  subtitle?: string;
}

const FacetFilterHeaderSubtitle = ({ className, subtitle }: FacetFilterHeaderSubtitleProps) => {
  if (!subtitle) return null;
  return (
    <Text type="small" className={cn('text-accent-100 md:hidden', className)}>
      {subtitle}
    </Text>
  );
};

interface FacetFilterHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
}

const FacetFilterHeader = ({ children, className }: FacetFilterHeaderProps) => (
  <div className={cn('flex flex-col gap-0', className)}>{children}</div>
);

export { FacetFilterHeader, FacetFilterHeaderSubtitle, FacetFilterHeaderTitle };
