import { useFacetColors } from 'hooks/useFacetColors';
import useRouter from 'hooks/useRouter';
import { localizedPathnames } from 'i18n/pathnames';
import { useState } from 'react';
import { Facet } from 'types/Facet';
import FacetCheckBox from '../FacetCheckBox/FacetCheckBox';
import FacetColorPill from '../FacetColorPill/FacetColorPill';
import FacetShowMore from '../FacetShowMore/FacetShowMore';

const MIN_AMOUNT_OF_ITEMS_TO_SHOW = 5;

type FacetColorSelectProps = {
  facetOptions: Facet[];
};

const FacetColorSelect = ({ facetOptions: unmappedFacetOptions }: FacetColorSelectProps) => {
  const router = useRouter();
  const { locale, pathname, query } = router;
  const [showAll, setShowAll] = useState(false);
  const { colorFulColors, colorLessColors } = useFacetColors({
    facetOptions: unmappedFacetOptions,
  });
  const facetOptions = [...colorFulColors, ...colorLessColors];
  const visibleFacets = showAll ? facetOptions : facetOptions.slice(0, MIN_AMOUNT_OF_ITEMS_TO_SHOW);

  const updateUrl = (newQuery?: string) => {
    if (newQuery === undefined) {
      return;
    }

    const localizedPathname = localizedPathnames[pathname]?.[locale] || pathname;

    router.push(
      {
        pathname: localizedPathname,
        query: {
          ...query,
          currentPage: 0,
          q: newQuery,
        },
      },
      undefined,
      { shallow: true },
    );

    window.scrollTo({ behavior: 'smooth', top: 0 });
  };
  return (
    <div className="flex flex-row flex-wrap gap-3">
      {visibleFacets.map((facetOption) =>
        facetOption.color ? (
          <FacetColorPill key={facetOption.code} className="flex items-center gap-2" facetOption={facetOption} />
        ) : (
          <FacetCheckBox
            key={facetOption.code}
            id={facetOption.code}
            className="flex w-full items-center gap-2"
            checked={facetOption.selected}
            onCheckedChange={() => updateUrl(facetOption.query?.query?.value)}
          >
            {facetOption.name} ({facetOption.count})
          </FacetCheckBox>
        ),
      )}
      <FacetShowMore
        showAll={showAll}
        setShowAll={setShowAll}
        amountOfItems={facetOptions?.length}
        minAmountOfItemsToShow={MIN_AMOUNT_OF_ITEMS_TO_SHOW}
      />
    </div>
  );
};
export default FacetColorSelect;
