'use client';

import { cn } from 'utils/cn';
import { Icon, IconProps } from '../Icon/Icon';

const InfoIcon = ({
  className,
  icon = { name: 'info-circle', styling: 'far' },
  ...props
}: Omit<IconProps, 'icon'> & { icon?: IconProps['icon'] }) => (
  <Icon className={cn('focus-ring text-base focus:text-primary-30', className)} icon={icon} {...props} />
);
InfoIcon.displayName = 'InfoIcon';

export { InfoIcon };
