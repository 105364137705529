import { getLocalizedPathname } from 'utils/localization';
import { getDynamicRouteParams } from 'utils/urlUtil';
import useRouter from './useRouter';

export const useFacetFilterActions = () => {
  const router = useRouter();
  const { locale, pathname, query } = router;
  const resetFacetFilters = () => {
    // Reset all query params that are not dynamic route params
    const dynamicParamKeys = getDynamicRouteParams(pathname);
    const newQuery = { ...query };
    Object.keys(newQuery).forEach((key) => {
      if (!dynamicParamKeys.includes(key)) {
        delete newQuery[key];
      }
    });
    const localizedPathname = getLocalizedPathname({
      locale,
      pathname,
    });
    router.push?.(
      {
        pathname: localizedPathname,
        query: newQuery,
      },
      undefined,
      { shallow: true },
    );
  };

  return { resetFacetFilters };
};
