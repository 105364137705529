import { ICON_NAMES } from 'constants/icon';
import { useFacetFilterActions } from 'hooks/useFacetFilterActions';
import { useIntl } from 'react-intl';
import { Button } from 'ui/Button/Button';
import { cn } from 'utils/cn';

interface FacetResetButtonProps {
  className?: string;
  onClick?: () => void;
}

const FacetResetButton = ({ className, onClick }: FacetResetButtonProps) => {
  const { resetFacetFilters } = useFacetFilterActions();
  const { formatMessage } = useIntl();
  return (
    <Button
      icon={{ name: ICON_NAMES.ERASER, styling: 'far' }}
      variant="ghost"
      className={cn('text-primary-30', className)}
      onClick={() => {
        if (onClick) {
          onClick();
        } else {
          resetFacetFilters();
        }
      }}
    >
      {formatMessage({
        id: 'search_facet_breadcrumbs_reset_filters',
      })}
    </Button>
  );
};
export default FacetResetButton;
