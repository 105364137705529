import styled from 'styled-components';
import { color, spacer, variable } from 'theme';
import Button from '../../Button/Button';
import Flex from '../../Flex/Flex';
import CheckList from '../../Layout/CheckList/CheckList';
import CheckListItem from '../../Layout/CheckList/CheckListItem/CheckListItem';

export const StyledWrapper = styled.div`
  background-color: ${color('accents-nature')};
  padding: ${spacer(300)};
  border-radius: ${variable('border-radius')};
  margin-bottom: ${spacer(300)};
  margin-top: ${spacer(300)};
`;

export const StyledContent = styled(Flex)`
  max-width: 400px;
  gap: ${spacer(150)};
`;

export const StyledChecklist = styled(CheckList)`
  margin-bottom: 0px;
`;

export const StyledChecklistItem = styled(CheckListItem)`
  svg {
    fill: ${color('white')};
  }
`;

export const StyledButton = styled(Button)`
  color: ${({ outlined }) => (outlined ? color('white') : color('accents-nature'))};

  border: 1px solid ${color('white')};
  background-color: ${({ outlined }) => (outlined ? color('accents-nature') : color('white'))};

  &:hover,
  &:active,
  &:focus {
    background-color: ${({ outlined }) => (outlined ? color('white') : color('accents-nature'))};
    color: ${({ outlined }) => (outlined ? color('accents-nature') : color('white'))};

    border-color: ${color('white')};
  }
`;
