import { Checkbox } from 'ui/Checkbox/Checkbox';
import { cn } from 'utils/cn';

type FacetCheckBoxProps = {
  checked?: boolean;
  children: React.ReactNode;
  className?: string;
  id: string;
  onCheckedChange: (checked: boolean) => void;
};

const FacetCheckBox = ({ checked, children, className, id, onCheckedChange }: FacetCheckBoxProps) => (
  <div className={cn('focus-visible-within-border has-[:focus-visible]:p-1', className)}>
    <Checkbox id={id} onCheckedChange={onCheckedChange} checked={checked} className="focus-visible:ring-0" />
    <label className="cursor-pointer" htmlFor={id}>
      {children}
    </label>
  </div>
);

export default FacetCheckBox;
