import { useWebConfig } from 'features/configuration/queries';
import { Facet } from 'types/Facet';

type useFacetColorsProps = {
  facetOptions: Facet[];
};

export const useFacetColors = ({ facetOptions }: useFacetColorsProps) => {
  const { data: webConfig } = useWebConfig();
  const colors = webConfig?.facetColours;

  if (!facetOptions || !colors) {
    return { colorFulColors: [], colorLessColors: [], selectedColors: [] };
  }

  const mappedColors = facetOptions?.map((facetValue) => {
    const foundColor = colors?.find((color) => color.name === facetValue?.name);

    if (foundColor) {
      return { ...facetValue, color: foundColor.colour } as unknown as Facet;
    }

    return facetValue as unknown as Facet;
  });

  const colorLessColors = mappedColors?.filter((facetColour) => !facetColour?.color);
  const colorFulColors = mappedColors?.filter((facetColour) => facetColour?.color);
  const selectedColors = colorFulColors?.filter((facetColour) => facetColour?.selected);

  return { colorFulColors, colorLessColors, selectedColors };
};
